<template v-if="!(activeSurveyId === null)">
	<div class="m-0" style="background-color:var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3))">
		<div v-if="goToError" class="alert alert-warning my-0 mx-2" role="alert">
			Hint: incorrect or invalid answer in section {{ goToError }}.
		</div>
	</div>
	<SurveyNav v-if="!noNav" :survey="survey" :active-survey-id="activeSurveyId" :class="'p-2'" :use-title="true" :is-valid="goToError ? false : true" :is-showing-preview="survey['isShowingPreview']"/>
	<SurveyComponent :id="activeSurveyId" :model="survey" />
	<SurveyNav v-if="!noNav" :survey="survey" :active-survey-id="activeSurveyId" :class="'p-2'" :use-title="false" :is-valid="goToError? false : true" :is-showing-preview="survey['isShowingPreview']"/>
</template>

<script lang="ts" setup>
	/** WCA Root Component */
	import { onMounted, watch, computed } from "vue";
	import "survey-core/defaultV2.min.css";
	StylesManager.applyTheme("defaultV2");
	import { StylesManager } from "survey-core";
	import { Model } from "survey-core";
	import { usePwaStore } from "@/stores/pwa";
	import SurveyNav from "@/views/pages/private/dashboard/surveys/SurveyNav.vue";

	import * as SurveyCore from "survey-core";
	// @ts-ignoreCore from "survey-core";
	// @ts-ignore
	import { nouislider } from "surveyjs-widgets";
	import "nouislider/dist/nouislider.css";
	nouislider(SurveyCore);

	import Inputmask from "inputmask";
	// @ts-ignore
	import { inputmask } from "surveyjs-widgets";
	inputmask(SurveyCore);

	const pwaStore = usePwaStore();

	const props = defineProps<{
		surveyJson: any;
		activeSurveyId: string;
		surveyData: any;
		noNav: boolean;
		noScroll: boolean;
		valid: number;
		isPlot?: boolean;
		index?: number
	}>();

	StylesManager.applyTheme("bootstrap");

	const survey = new Model(props.surveyJson);
	watch(() => props.surveyData, (newSurveyData: any) =>{
		setSurveyData(newSurveyData);
		console.log("saw a change to data");

	});

	watch(() => props.surveyJson, (newSurveyJson: any) => {
		survey.setJsonObject(newSurveyJson);
		console.log("saw a change to json");
	});

	function setSurveyData (woodlandSurvey: any) {
		if (woodlandSurvey == null) return;
		// Restore survey results
		const prevData = woodlandSurvey;

		if (prevData) {
			const data = prevData;
			survey.data = data;
			if (data.pageNo) {
				survey.currentPageNo = data.pageNo;
			}
		}
	}

	// actioned on survey complete button
	const alertResults = (sender: any) => {
		alert("Your woodland survey is complete. You may still edit your answers until the survey itself is submitted. Simply return to this page.");
	};

	// SAVE SURVEY ROUTES

	// actioned on next button
	const surveyNext = (sender: any) => {
		if (!survey) return;
		const data = survey.data;
		data.pageNo = survey.currentPageNo;
		saveSurveyResults();
	};

	// actioned when survey complete button is hit
	const surveyComplete = (sender: any) => {
		surveyNext(sender);
	};

	const emit = defineEmits(["update-survey"]);
	async function saveSurveyResults () {
		emit("update-survey", survey.data, survey.validate(), props.activeSurveyId);
	}

	const goToError = computed(() => {

		// if (!survey["isShowingPreview"])
		// 	return props.survey.validate();

		let hasError = false;
		let currentPage = null;
		// console.log(props.surveyJson);
		// console.log(survey.pages);
		let X = null;
		survey.pages.forEach(page => {
			if (!hasError) {
				if (page.visible && !(page.name == "all")) page.questions.forEach(question => {
					// console.log(question.isVisible);
					if (question.isVisible && question.isRequired &&
						(question.isEmpty() || question.hasErrors()) &&
						(question.renderAs != "image")) {
						hasError = true;
						currentPage = page;
						// Navigate to the first page with an error

						if (props.isPlot) console.log("currentPage.name (" + props.index + "): " + currentPage.name);
						// if (page.name == "all") return null;
						// console.log(page);
						X =  currentPage.title;
					}
				});
			}
		});
		return X;
	});

	// onMounted(() => {
	function initSurvey () {
		// survey.onComplete.add(alertResults);
		survey.onPartialSend.add(surveyNext);
		survey.onComplete.add(surveyComplete);
		survey.onShowingPreview.add(surveyNext);
		survey.showPreviousButton = false;

		// const survey = new SurveyVue.SurveyModel(json);
		survey.onValueChanged.add(function (sender, options) {
			if (options.name === "question11" && options.value.indexOf("complex") !== -1) {
				// Deselect all other options if "Other" is selected
				const otherIndex = options.value.indexOf("complex");
				if (otherIndex > -1) {
					let data = survey.data;
					if (otherIndex == (options.value.length - 1)) {
						data.question11 = ["complex"];
					}
					else {
						data.question11.splice(otherIndex, 1);
					}
					survey.data = (data);
				}
			}
		});

		if (props.noScroll) {
			survey.onScrollingElementToTop.add((sender, options) => {
				// Remember scroll position before value change
				options.cancel = true;
			});
		}

		// Restore survey results
		setSurveyData(props.surveyData);

		// emit("update-survey", survey.data, survey.validate(), props.activeSurveyId);
	}
	initSurvey();
	// });
</script>

<style>
/* .accordion-button:hover {
  background-color: rgba(var(--wca-menu-background),0.6)!important;
}
.accordion-button {
  background-color: rgba(var(--wca-background),0.1)!important;
  background: rgba(var(--wca-background),0.1)!important
}
 .accordion-button:not(.collapsed) {
    background-color: rgba(var(--wca-menu-background),0.8)!important;
}
.accordion-button:not(.collapsed):hover {
	background-color: rgba(var(--wca-menu-background),1)!important;
} */

.noUi-origin{
	width:10%;
}
.sd-small-btn {
	max-width: 100px;
	padding: calc(2*(var(--sjs-base-unit, var(--base-unit, 8px)))) calc(3*(var(--sjs-base-unit, var(--base-unit, 8px))));
}
</style>
