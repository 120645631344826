<template>
	<div ref="mapContainer" class="map-container"/>
	<div id="popup" class="ol-popup"/>
</template>

<script>
	// OpenLayers default styles
	import "ol/ol.css";

	import Map from "ol/Map";
	import View from "ol/View";
	import TileLayer from "ol/layer/Tile";
	import OSM from "ol/source/OSM";
	import { Feature } from "ol";
	import { Point } from "ol/geom";
	import { fromLonLat } from "ol/proj";
	import VectorSource from "ol/source/Vector";
	import VectorLayer from "ol/layer/Vector";
	import { Style, Circle, Fill, Icon } from "ol/style";
	import Overlay from "ol/Overlay";

	export default {
		name: "MapWithMarkers",
		props: {
			coordinates: {
				type: Array,
				required: true,
				default: () => [
				]
			},
			names: {
				type: Array,
				required: true,
				default: () => [
				]
			},
			center: {
				type: Array,
				required: true,
				default: () => [-0.1276, 51.5074]
				// Example: London
			},
			activeLocation: {
				type: Array,
				required: false,
				default: () => [-0.1276, 51.5074]
				// Default to London
			}
		},
		data () {
			return {
				map: null,
				activeLocationFeature: null,
				markerSource: null,
				popup: null
			};
		},
		watch: {
			activeLocation (newLocation) {
				this.updateActiveLocation(newLocation);
			},
			coordinates: {
				handler (newCoordinates) {
					this.updateMarkers(newCoordinates);
				},
				deep: true
			},
			names: {
				handler () {
					this.updateMarkers(coordinates);
				},
				deep: true
			}
		},
		mounted () {
			this.initializeMap();
			this.updateActiveLocation(this.activeLocation);
		},
		methods: {
			initializeMap () {
				// Create a tile layer for the map
				const tileLayer = new TileLayer({
					source: new OSM()
				});

				// Create a vector source and layer for the markers
				this.markerSource = new VectorSource();
				const markerLayer = new VectorLayer({
					source: this.markerSource
				});

				// Create a vector source and layer for the active location
				const activeSource = new VectorSource();
				this.activeLocationFeature = new Feature({
					geometry: new Point(fromLonLat(this.activeLocation)),
					name: "Current location"
				});
				// blue dot live location
				this.activeLocationFeature.setStyle(
					new Style({
						image: new Circle({
							radius: 5,
							fill: new Fill({ color: "rgba(0,0,255,0.5)" })
						})
					})
				);
				activeSource.addFeature(this.activeLocationFeature);

				const activeLayer = new VectorLayer({
					source: activeSource
				});

				// Create the map
				this.map = new Map({
					target: this.$refs.mapContainer,
					layers: [tileLayer, markerLayer, activeLayer],
					view: new View({
						center: fromLonLat(this.center),
						zoom: 16
					})
				});

				// Initialize popup
				this.initializePopup();

				// Initialize markers
				this.updateMarkers(this.coordinates);
			},
			initializePopup () {
				const popupElement = document.getElementById("popup");

				// Create a popup overlay
				this.popup = new Overlay({
					element: popupElement,
					autoPan: true,
					autoPanAnimation: {
						duration: 250
					}
				});
				this.map.addOverlay(this.popup);

				// Add map click event
				this.map.on("click", (event) => {
					const feature = this.map.forEachFeatureAtPixel(event.pixel, (feat) => feat);

					if (feature) {
						const coordinates = feature.getGeometry().getCoordinates();
						const name = feature.get("name") || "No information available";

						popupElement.innerHTML = `${name}`;
						this.popup.setPosition(coordinates);
					} else {
						this.popup.setPosition(undefined);
					}
				});
			},
			updateActiveLocation (newLocation) {
				if (this.activeLocationFeature) {
					this.activeLocationFeature.getGeometry().setCoordinates(fromLonLat(newLocation));
					// this.map.getView().setCenter(fromLonLat(newLocation)); // Optionally center the map on the active location
				}
			},
			updateMarkers (newCoordinates) {
				// Clear existing markers
				this.markerSource.clear();

				// Add new markers
				newCoordinates.forEach((coord, ind) => {
					const feature = new Feature({
						geometry: new Point(fromLonLat(coord)),
						name: this.names[ind]
					});
					// plot location yellow circle
					feature.setStyle(
						new Style({
							image: new Circle({
								radius: 7,
								fill: new Fill({ color: "yellow" })
							})
						})
					);
					this.markerSource.addFeature(feature);
				});

				// same for center
				const feature2 = new Feature({
					geometry: new Point(fromLonLat(this.center)),
					name: "Centre point of markers"
				});
				feature2.setStyle(
					new Style({
						image: new Icon({
							src: "/map_marker.png",
							scale: 0.05
						})
					})
				);
				this.markerSource.addFeature(feature2);

				this.map.getView().setCenter(fromLonLat(this.center));
				// Optionally center the map on the active location

			}
		}
	};
</script>

<style>
.map-container {
	width: 100%;
	height: 500px;
}
.ol-popup {
	position: absolute;
	background-color: white;
	padding: 10px;
	border: 1px solid #cccccc;
	border-radius: 4px;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
	white-space: nowrap;
	pointer-events: auto;
}

.ol-popup:after {
	content: "";
	position: absolute;
	bottom: -10px;
	left: 10px;
	border-width: 10px;
	border-style: solid;
	border-color: white transparent transparent transparent;
}
</style>
